import propTypes from 'prop-types'

const Discover = ({ size }) => {
  return (
    <svg
      width={size}
      version="1.1"
      // enableBackground="new 0 0 780 500"
      viewBox="0 0 780 500"
    >
      <path
        d="m90.682 228.13c-6.922 6.002-15.916 8.622-30.154 8.622h-5.911v-71.747h5.912c14.237 0 22.875 2.449 30.154 8.774 7.619 6.519 12.204 16.616 12.204 27.011 0 10.414-4.584 20.821-12.205 27.34z"
        fill="currentColor"
      />
      <path
        d="m682.92 196.57h-6.438v-32.86h6.787c13.721 0 21.18 5.525 21.18 16.083 0 10.908-7.459 16.777-21.529 16.777z"
        fill="currentColor"
      />
      <path
        d="m736.99 0h-695.48c-22.927 0-41.512 18.99-41.512 42.426v414.65c0 23.432 18.595 42.426 41.512 42.426h695.48c22.926 0 41.512-18.989 41.512-42.426v-414.65c-1e-3 -23.432-18.596-42.426-41.513-42.426zm-82.518 146.62h32.671c25.413 0 39.977 11.721 39.977 32.034 0 16.612-9.135 27.515-25.731 30.759l35.559 45.708h-27.097l-30.496-43.587h-2.873v43.587h-22.01v-108.5zm-77.079 0h62.486v18.385h-40.47v24.082h38.977v18.377h-38.977v29.289h40.47v18.368h-62.486v-108.5zm-94.49-0.167l30.126 72.878 30.515-72.878h23.885l-48.8 111.28h-11.857l-47.936-111.28h24.067zm-137.4 54.362c0-31.879 26.906-57.684 60.096-57.684 33.188 0 60.094 25.806 60.094 57.684v0.038c0 31.879-26.905 57.72-60.094 57.72-33.191 0-60.096-25.842-60.096-57.72v-0.038zm-94.427-0.163c0-31.548 26.764-56.778 60.146-56.778 9.479 0 17.431 1.953 27.106 6.665v24.899c-9.165-8.605-17.117-12.206-27.63-12.206-20.664 0-36.916 16.432-36.916 37.265 0 21.969 15.75 37.425 37.942 37.425 9.989 0 17.792-3.417 26.604-11.884v24.913c-10.021 4.53-18.143 6.32-27.63 6.32-33.551 0-59.622-24.728-59.622-56.619zm-36.235 10.933c-2.216-1.309-6.606-3.258-15.232-6.18-20.693-7.155-27.793-14.805-27.793-29.752 0-17.757 15.246-31.091 35.232-31.091 12.385 0 23.717 4.07 33.193 12.03l-11.53 14.5c-5.739-6.179-11.165-8.786-17.766-8.786-9.497 0-16.416 5.193-16.416 12.026 0 5.86 3.884 8.963 17.106 13.661 25.064 8.803 32.492 16.607 32.492 33.845 0 21.004-16.064 35.625-38.961 35.625-16.766 0-28.956-6.342-39.107-20.653l14.232-13.166c5.076 9.424 13.54 14.469 24.05 14.469 9.829 0 17.106-6.514 17.106-15.303 0-4.556-2.205-8.463-6.606-11.225zm-109.76 31.011c-10.843 8.647-23.198 12.524-40.306 12.524h-32.172v-108.5h32.342c35.734 0 60.642 22.305 60.642 54.326 0 15.967-7.62 31.405-20.506 41.65zm30.645-95.976h22.035v108.5h-22.034l-1e-3 -108.5zm633.29 307.8c0 19.908-15.802 36.049-35.294 36.049h-578.61c397.1-69.975 613.91-226.32 613.91-226.32v190.27z"
        fill="currentColor"
      />
    </svg>
  )
}

Discover.displayName = 'Discover'
Discover.propTypes = {
  size: propTypes.number,
}

export default Discover
